<template>
  <div class="flex flex-col overflow-hidden min-h-[calc(100dvh-67px)]">
    <div v-if="props.header">
      <Header></Header>
    </div>
    <div class="flex-1">
      <slot></slot>
    </div>
    <div v-if="props.footer">
      <Footer></Footer>
    </div>
  </div>
</template>
<script setup>
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer/Footer.vue";
import {provide,inject, reactive} from "vue";

/*const props = defineProps({
  header: {
    type: Boolean,
    default: true
  },
  footer: {
    type: Boolean,
    default: true
  }
})*/

const defaultProps = {
  header: true,
  footer: true
};

const providedProps = inject('searchBasicLayoutProps', defaultProps);
const props = reactive({ ...defaultProps, ...providedProps });

/*function updateHeader(value) {
  defaultProps.header = value
}
function updateFooter(value) {
  defaultProps.footer = value
}

provide('searchBasicLayoutSetting', {
  updateHeader,
  updateFooter
})*/

</script>
<style scoped
       lang="scss">

</style>
