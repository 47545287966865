<template>
  <ul class="h-[66px] flex items-center pc:mx-[100px]">
    <li @click="goHome({})">
      <img class="h-[30px]"
           src="@/assets/image/logo/osn-zh-horizon.png"
           alt="logo"
      />
    </li>
    <li class="grow"></li>
    <li v-if="!isLogin" class="flex items-center"
        @click="toWebsite(backstageUrl)">
      {{ $t('menu.i_am_owner') }}
      <img src="@/assets/image/icon/menu/open_in_new.svg"
           alt="">
    </li>
    <li v-if="!isLogin" class="px-[24px] h-[40px] leading-[40px] bg-osn-yellow-00 rounded-full text-[14px] font-semibold"
        @click="handelLoginBtn">{{ $t('menu.login_reservation') }}
    </li>

    <li v-if="isLogin" class="flex items-center"
        @click="go('/account')">
      <img class="w-[20px] h-[20px] mr-[6px]"
           src="@/assets/image/icon/menu/account_circle.svg"
           alt="">
      {{ $t('menu.account_manager') }}
    </li>
    <li v-if="isLogin" class="flex items-center"
        @click="go('/order')">
      <img class="w-[20px] h-[20px] mr-[6px]"
           src="@/assets/image/icon/menu/list_alt.svg"
           alt="">
      {{ $t('menu.check_order') }}
    </li>
    <li v-if="isLogin" class="flex items-center"
        @click="handleLogout">
      <img class="w-[20px] h-[20px] mr-[6px]"
           src="@/assets/image/icon/menu/logout.svg"
           alt="">
      {{ $t('menu.logout') }}
    </li>
  </ul>
</template>
<script setup>
import {computed, ref} from 'vue'
import {useGoTo} from "@/composables/useGoTo";
import {useUserStore} from "~/stores/user";


const userStore = useUserStore()
const {go, toWebsite, goHome, handelLoginBtn} = useGoTo()
const isLogin = computed(() => userStore.getToken);
const backstageUrl = ref(import.meta.env.VITE_APP_BASE_BACKSTAGE_URL)

const props = defineProps({
  handleLogout: {
    type: Function
  }
})

</script>
<style scoped
       lang="scss">
  li {
    @apply pr-[30px] text-[16px] cursor-pointer;
  }

</style>
