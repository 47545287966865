<template>
  <div class="side-bar-content-wrap">
    <!--      側欄 header-->
    <div class="side-bar-header">
      <div class="logo-title">
        <img src="../../assets/image/logo/osn-zh-horizon2.png"
             alt=""/>
      </div>
      <div v-if="!isLogin"
           class="login-btn-group">
        <div @click="handelLoginBtn">{{ $t('menu.login') }}</div>
        <div @click="toWebsite(backstageLoginUrl)">{{ $t('menu.owner_login') }}</div>
      </div>
      <div v-else
           class="login-wrap">
        <div>{{ $t('menu.welcome') + name }}</div>
        <div @click="handleLogout"><img src="@/assets/image/icon/menu/logout.svg"
                                        alt=""></div>
      </div>
    </div>
    <!--      側欄內容-->
    <ul class="side-bar-body">
      <li v-if="isLogin" @click="go('/account')">
        <img src="@/assets/image/icon/menu/account_circle.svg"
             alt="">
        {{ $t('menu.account_manager') }}
      </li>
      <li v-if="isLogin" @click="go('/order')">
        <img src="@/assets/image/icon/menu/list_alt.svg"
             alt="">
        {{ $t('menu.check_order') }}
      </li>
      <li @click="toWebsite(lineUrl)">
        <img src="@/assets/image/icon/menu/line.svg"
             alt="">
        {{ $t('menu.line') }}
      </li>
      <li @click="toWebsite(helpUrl)">
        <img src="@/assets/image/icon/menu/info.svg"
             alt="">
        {{ $t('menu.help_center') }}
      </li>
      <li @click="toWebsite(clientUrl)">
        <img src="@/assets/image/icon/menu/policy.svg"
             alt="">
        {{ $t('menu.contract_terms') }}
      </li>
      <li @click="toWebsite(privacyUrl)">
        <img src="@/assets/image/icon/menu/privacy.svg"
             alt="">
        {{ $t('menu.privacy_policy') }}
      </li>
    </ul>
    <!--      側欄 footer-->
    <div class="side-bar-footer">
      <ul>
        <li @click="toWebsite(hostUrl)">{{ $t('menu.have_space') }}</li>
        <li @click="toWebsite(hostUrl)">{{ $t('menu.join_us') }}
          <img src="../../assets/image/icon/svg/arrow_circle_right.svg"
               alt="">
        </li>
        <li>Copyright © 2024 One Step Technology Ltd.</li>
        <li>Powered by OneStep Version 1.0</li>
      </ul>

    </div>
  </div>

  <el-dialog
      v-model="loginDialogVisible"
      width="30%"
      :show-close="false"
      align-center
  >
    <LoginLayout @onCloseLoginDialog="handelLoginDone"></LoginLayout>
  </el-dialog>
</template>
<script setup>
import {computed, ref} from "vue";
import {useGoTo} from "@/composables/useGoTo";
import {useUserStore} from "~/stores/user.ts";
import {useSettingStore} from "@/stores/setting.js";
import LoginLayout from "@/components/LoginLayout.vue";

const props = defineProps({
  handleDrawerClose: {
    type: Function
  }
})

const userStore = useUserStore()
const settingStore = useSettingStore()
const isMobile = computed(() => settingStore.isMobile);
const isLogin = computed(() => userStore.getToken);
const name = computed(() => userStore.getName);
const {go, toWebsite} = useGoTo()
const loginDialogVisible = ref(false)

// 外連 url
const hostUrl = ref(import.meta.env.VITE_APP_BASE_HOST_URL)
const faceBookUrl = ref(import.meta.env.VITE_APP_BASE_FACEBOOK_URL)
const lineUrl = ref(import.meta.env.VITE_APP_BASE_LINE_URL)
const helpUrl = ref(import.meta.env.VITE_APP_BASE_HELP_URL)
const clientUrl = ref(import.meta.env.VITE_APP_BASE_CLIENT_URL)
const privacyUrl = ref(import.meta.env.VITE_APP_BASE_PRIVACY_URL)
const backstageLoginUrl = ref(import.meta.env.VITE_APP_BASE_BACKSTAGE_URL)

async function handleLogout() {
  await userStore.logout()
}

function handelLoginBtn() {
  if (isMobile.value) {
    go('/login')
  } else {
    loginDialogVisible.value = true
  }
}

function handelLoginDone() {
  loginDialogVisible.value = false
  props.handleDrawerClose()
}
</script>
<style scoped
       lang="scss">
.side-bar-content-wrap {
  @apply px-[30px] py-0 flex flex-col h-full;
  .side-bar-header{
    @apply text-os-gray-900 px-0 pt-[87px] mb-0;
    .logo-title {
      @apply flex justify-center items-center;

      img {
        @apply mb-[36px]
        md:max-h-[70px]
        max-h-[50px];

      }
    }

    .login-btn-group {
      @apply flex justify-center;
      div {
        @apply w-[131px] h-[44px] leading-[44px] text-center rounded-full text-[14px] font-semibold cursor-pointer;
      }

      div:first-child {
        @apply bg-osn-yellow-00 text-osn-black-00 mr-[8px];
      }

      div:nth-child(2) {
        @apply bg-osn-black-00 text-osn-yellow-01;
      }

    }

    .login-wrap {
      @apply flex text-osn-grey-00 text-[18px] items-center justify-between pb-[27px] border-b border-[#C3C3C1];

      div:nth-child(2) {
        @apply cursor-pointer;
      }
    }


    .el-drawer__close-btn {
      @apply text-4xl absolute top-[64px] right-[30px];
    }
  }
  ul.side-bar-body {
    @apply text-[16px] py-[34px];
    li {
      @apply flex mb-[32px] text-[16px] text-osn-black-00 cursor-pointer;
      img {
        @apply mr-[12px]
      }
    }
  }
  .side-bar-footer{
    @apply mt-auto pt-[30px] pb-[60px] px-0 border-t border-gray-300 text-[14px];

    ul {
      @apply text-left text-[16px] text-osn-black-00;
      li {
        @apply flex mb-[12px] cursor-pointer;
      }
      li:nth-child(3),li:nth-child(4){
        @apply text-[12px] text-osn-dark-grey-43
      }
    }
  }
}
</style>
